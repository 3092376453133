import { Card, Col } from "react-bootstrap";
import frameworksca from '../../../../images/frameworksca.JPG';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

function Frameworksca() {
    return (
        <>
        <Col sm={12} md={6} lg={4}>
            <Card>
                <Card.Img variant="top" src={frameworksca} alt="Front page of JS Frameworks CA page" />
                <Card.Body>
                    <Card.Text>
                        <FontAwesomeIcon icon="fab fa-html5" size="2xl" className="icons"/>
                        <FontAwesomeIcon icon="fab fa-css3-alt" size="2xl" className="icons"/>
                        <FontAwesomeIcon icon="fab fa-js-square" size="2xl" className="icons"/>
                        <FontAwesomeIcon icon="fab fa-bootstrap" size="2xl" className="icons"/>
                        <FontAwesomeIcon icon="fab fa-react" size="2xl" className="icons"/>
                    </Card.Text>
                    <h2>JS Frameworks Course Assignment</h2>
                    <Card.Text>
                        <h5>TASK</h5>
                        <p>Set up following sites: "/", "/detail/param", "/contact", "/login", "admin". Admin path will not appear in navigation. Find an API that returns at least an array of items and a single item retrieved by a parameter. API has to be your own and public.</p>
                        <h5>REQUIREMENT</h5>
                        <p>#Home will display at least 2 properties and link to details page, passing a parameter. #Detail will retrieve the parameter and display atleast 3 properties. #Contact will be a form, type contact. Input fields set up with different requirments. #Login - create a login form, and send a request to API. #Admin - this site will only display an "Admin" heading.</p>
                        <h5>END PRODUCT</h5>
                        <p>For this course assignment I did struggle some. I have gone back to make some quick fixes, as there were issues with node_modules and the package.json file. Requirements are met, except the details site. Also, design is pore (non existent). Not a project I am proud to display, but it has a foundation I can build on and make this a good contribution to my portfolio.</p>
                    </Card.Text>
                    <a href="https://github.com/Noroff-FEU-Assignments/js-frameworks-course-assignment-Cathrinesj" target="_blank" rel="noopener noreferrer"><button variant="primary">GitHub</button></a>
                </Card.Body>
            </Card>  
        </Col>
        </>
    )
}

export default Frameworksca;