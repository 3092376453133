import { Card, Col } from "react-bootstrap";
import gamehub from "../../../../images/gamehub.JPG";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

function Gamehub() {
    return (
        <>
        <Col sm={12} md={6} lg={4}>
            <Card>
                <Card.Img variant="top" src={gamehub} />
                <Card.Body>
                    <Card.Text>
                        <FontAwesomeIcon icon="fab fa-html5" size="2xl" className="icons"/>
                        <FontAwesomeIcon icon="fab fa-css3-alt" size="2xl" className="icons"/>
                        <FontAwesomeIcon icon="fab fa-js-square" size="2xl" className="icons"/>
                    </Card.Text>
                    <h2>Gamehub</h2>
                    <Card.Text>
                        <h5>TASK</h5>
                        <p>Game Hub was a cross-course project our first year at Noroff, and therefore gradually built through the year. It started of quite basic with HTML and CSS and was also used to learn more about design and user experience. </p>
                        <h5>REQUIREMENT</h5>
                        <p>Requirements varied through the year</p>
                        <h5>END PRODUCT</h5>
                        <p>End product also have some added JS. It was interesting and challenging to go back to a project and make changes, but a great learning experience.</p>
                    </Card.Text>
                    <a href="https://github.com/Cathrinesj/Game-hub-ver2" target="_blank" rel="noopener noreferrer"><button variant="primary">GitHub</button></a>
                    <a href="https://cathrinesgamehub.netlify.app/" target="_blank" rel="noopener noreferrer"><button variant="primary">Live site</button></a>
                </Card.Body>
            </Card>  
        </Col>
        </>
    )
}

export default Gamehub;