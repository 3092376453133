import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import { HashLink as Link } from 'react-router-hash-link';
import { Stack } from 'react-bootstrap';

function Navigation() {
  
  return (
    <Navbar expand="lg mb-2" fixed="top">
      <Container>
        <Navbar.Brand href="/*">Feminite</Navbar.Brand>
        <Nav className="d-none d-md-block sticky-top" defaultActiveKey="/*">
          <Stack direction="horizontal" gap={4} className='mx-auto'>
            <Link to="#about">About</Link>
            <Link to="#portfolio">Portfolio</Link>
            <Link to="#contact">Contact</Link>
          </Stack>
        </Nav>

        <Nav className="navbar d-md-none fixed-bottom" defaultActiveKey="/*">
          <Stack direction="horizontal" gap={4} className='mx-auto'>
            <Link to="#about">About</Link>
            <Link to="#portfolio">Portfolio</Link>
            <Link to="#contact">Contact</Link>   
          </Stack>
        </Nav>
       </Container>
    </Navbar>
    );
  }
  
  export default Navigation;