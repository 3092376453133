import { Card, Col } from "react-bootstrap";
import wanderlust from "../../../../images/wanderlust.JPG"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

function Wanderlust() {
    return (
        <>
        <Col sm={12} md={6} lg={4}>
            <Card>
                <Card.Img variant="top" src={wanderlust} alt="Front page of wanderlust web page" />
                <Card.Body>
                    <Card.Text>
                        <FontAwesomeIcon icon="fab fa-html5" size="2xl" className="icons"/>
                        <FontAwesomeIcon icon="fab fa-css3-alt" size="2xl" className="icons"/>
                        <FontAwesomeIcon icon="fab fa-js-square" size="2xl" className="icons"/>
                    </Card.Text>
                    <h2>Wanderlust</h2>
                    <Card.Text>
                        <h5>TASK</h5>
                        <p>Wanderlust blog was my delivery for our Project Exam first year. We were free to decide topic of our blog. Wordpress was used as headless CMS.</p>
                        <h5>REQUIREMENT</h5>
                        <p>Certain requirements, such as a carousel/slider at index, enlarged image on click on blog and contact form with warning text and rules.</p>
                        <h5>END PRODUCT</h5>
                        <p>Originally made with HTML, CSS and vanilla JS. I was quite happy with the end result, but are planning on spending the summer to make some adjustments to it.</p>
                    </Card.Text>
                    <a href="https://github.com/Cathrinesj/ProjExamPortfolio" target="_blank" rel="noopener noreferrer"><button variant="primary">GitHub</button></a>
                    <a href="https://wanderlustblogg.netlify.app/" target="_blank" rel="noopener noreferrer"><button variant="primary">Live site</button></a>
                </Card.Body>
            </Card>  
        </Col>
        </>
    )
}

export default Wanderlust;