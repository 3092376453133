import { Card, Col } from "react-bootstrap";
import communityciencemuseum from "../../../../images/communityScienceMuseum.JPG"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

function CommunityScienceMuseum() {
    return (
        <>
        <Col sm={12} md={6} lg={4}>
            <Card>
                <Card.Img variant="top" src={communityciencemuseum} alt="Front page of communityscience museum web page" />
                <Card.Body>
                    <Card.Text>
                        <FontAwesomeIcon icon="fab fa-html5" size="2xl" className="icons"/>
                        <FontAwesomeIcon icon="fab fa-css3-alt" size="2xl" className="icons"/>
                        <FontAwesomeIcon icon="fab fa-js-square" size="2xl" className="icons"/>
                    </Card.Text>
                    <h2>Community Science Museum</h2>
                    <Card.Text>
                        <h5>TASK</h5>
                        <p>First year Semester Project: “An interactive science museum called the Community Science Museum is opening in your town/city. Its core target audience is primary and middle school children (7-15) and families with young children. The museum wants a website that is informative and appealing, that will attract both middle school pupils and their parents. The site should appeal to youngsters without pandering; it should take for granted that the audience is inquisitive and intelligent. The website should be informative and engaging but should encourage viewers to visit the museum itself. The website should be responsive and be easy to use on a variety of devices.”</p>
                        <h5>REQUIREMENT</h5>
                        <p>The site must have a design that appeals to the target audience, and have at least 4-6 pages.
                        Compositional principles, typography, and color schemes must be considered.
                        HTML should be semantic and neatly indente, and use external CSS style sheets which follow the DRY principle.
                        The site should download quickly, and not be overly "heavy". Images should not be over 200kb.
                        WCAG principles should be taken into consideration, especially regarding hierarchy, navigation, and color usage.
                        The site should be responsive, attractive, and easy to use on a variety of devices.
                        The site should show consideration for content strategy and SEO.
                        </p>
                        <h5>END PRODUCT</h5>
                        <p>Original site was based on HTML and CSS only. I have later added JS, and done changes based on feedback on the assignment.</p>
                    </Card.Text>
                    <a href="https://github.com/Cathrinesj/2021-09-05_SA1_Cathrine_Sleire_Juvsett_Github" target="_blank" rel="noopener noreferrer"><button variant="primary">GitHub</button></a>
                    <a href="https://cathrinesmuseum.netlify.app/" target="_blank" rel="noopener noreferrer"><button variant="primary">Live site</button></a>
                </Card.Body>
            </Card>  
        </Col>
        </>
    )
}

export default CommunityScienceMuseum;