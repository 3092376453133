import { Card, Col } from "react-bootstrap";
import sevi from "../../../../images/sevi.JPG"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

function Sevi() {
    return (
        <>
        <Col sm={12} md={6} lg={4}>
            <Card>
                <Card.Img variant="top" src={sevi} alt="Front page of Sevi web page" />
                <Card.Body>
                    <Card.Text>
                        <FontAwesomeIcon icon="fab fa-html5" size="2xl" className="icons"/>
                        <FontAwesomeIcon icon="fab fa-css3-alt" size="2xl" className="icons"/>
                        <FontAwesomeIcon icon="fab fa-js-square" size="2xl" className="icons"/>
                        <FontAwesomeIcon icon="fab fa-bootstrap" size="2xl" className="icons"/>
                        <FontAwesomeIcon icon="fab fa-sass" size="2xl" className="icons"/>
                    </Card.Text>
                    <h2>SeVi - Second Hand Vintage</h2>
                    <Card.Text>
                        <h5>TASK</h5>
                        <p>Build an e-commerce website, theme is up for choice. Favourite tool of choosing for design. Logo can be found or made, but not to be spent to much time on. Good user experience, and UI design, following todays trends and design patterns.</p>
                        <h5>REQUIREMENT</h5>
                        <p>Build a front end with home, product list, product detail and cart pages. Checkout and payment not part of project. Admin pages to create, update and delete. Strapi API to be provided, or set up self.</p>
                        <h5>END PRODUCT</h5>
                        <p>I decided to make a second hand vintage shop with my Strapi API. End product is made with HTML, CSS, Vanilla JS, bootstrap and SASS. A great learning experince building this one.</p>
                    </Card.Text>
                    <a href="https://github.com/Cathrinesj/cathrine-sleire-juvsett-semester-project-2" target="_blank" rel="noopener noreferrer"><button variant="primary">GitHub</button></a>
                    <a href="https://sevi-semesterproject.netlify.app/" target="_blank" rel="noopener noreferrer"><button variant="primary">Live site</button></a>
                </Card.Body>
            </Card>  
        </Col>
        </>
    )
}

export default Sevi;