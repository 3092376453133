import { Card} from "react-bootstrap";

function About () {

    return (
        <>
        <Card className="opacity" id="about">
            <Card.Body>
                <Card.Text>
                    <h2>About Me</h2>
                    <p>Currently I am Digital Sales Manager at Frydenbø Industri (Since June 2022). Previously, I have been with Frydenbø from 2010 – 2021, where I worked in sales/aftermarket, and project Manager when renewing website and setting up new web shop. I had a year break to study full time, and are now back, while I finish up last semester part time. Before joining Frydenbø, I studied at BI Bergen, BI Oslo and had six months abroad at Wirtschäftsuniversitet Wien. I finished my bachelor’s in business administration in June 2010. </p>
                    <p>When working hours are done, I spend time with my family and friends. I have 3 children, and are married to my favourite person. I am football coach to “gutter 2014” at our local club – Østsiden Askøy, and are taking courses to be “breddetrener” in the end.  I am chairman of board for our homeowner’s association and private ground association, which has given me valuable lessons. I have previously also been a board member of a boat association. I am currently in to meditiation/yoga (trying to learn that is), a good show on Netflix and spending time with my friends whenever possible. I am very social, enjoy spending time with people and loves to a good conversation with anyone who loves what the do, be at a hobby or job. </p>
                </Card.Text>
            </Card.Body>
        </Card>
        </>
    );
}

export default About;
