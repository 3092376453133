import { Row } from 'react-bootstrap';
import Card from 'react-bootstrap/Card';
import Gamehub from '../content/GameHub';
import CommunityScienceMuseum from '../content/CommunityScienceMuseum';
import Wanderlust from '../content/Wanderlust';
import Sevi from '../content/Sevi';
import Some from '../content/Some';
import Frameworksca from '../content/Frameworksca';

function Portfolio () {

    return (
        <>
        <Card className='opacity' id="portfolio">
            <Card.Body>
                <h1>Portfolio</h1>
                <p>Current Portfolio of my most relevant school projects and languages used</p>
                <Row>
                    <Some />
                    <Sevi />
                    <Frameworksca />
                    <Wanderlust />
                    <CommunityScienceMuseum /> 
                    <Gamehub/>
                </Row>
            </Card.Body>
        </Card>
    </>
    );
}

export default Portfolio;