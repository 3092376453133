import React from "react";
import Navigation from "../../components/layout/Navigation";
import Intro from "../../components/Intro";
import About from "../../components/About";
import Portfolio from "../../components/myPortfolio/Portfolio";
import ContactForm from "../../components/Contact";
import { Container } from "react-bootstrap";

function Home () {
    return (
        <>
        <Navigation/>
        <Container fluid="md">
            <Intro />
            <About />
            <Portfolio />
            <ContactForm />
        </Container>
        </>
    )
}

export default Home;