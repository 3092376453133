import { Card, Col } from "react-bootstrap";
import some from "../../../../images/someSnip.JPG"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

function Some() {
    return (
        <>
        <Col sm={12} md={6} lg={4}>
            <Card>
                <Card.Img variant="top" src={some} alt="Front page of SoMe web page" />
                <Card.Body>
                    <Card.Text>
                        <FontAwesomeIcon icon="fab fa-html5" size="2xl" className="icons"/>
                        <FontAwesomeIcon icon="fab fa-css3-alt" size="2xl" className="icons"/>
                        <FontAwesomeIcon icon="fab fa-js-square" size="2xl" className="icons"/>
                        <FontAwesomeIcon icon="fab fa-bootstrap" size="2xl" className="icons"/>
                        <FontAwesomeIcon icon="fab fa-react" size="2xl" className="icons"/>
                    </Card.Text>
                    <h2>SoMe - Shape your future</h2>
                    <Card.Text>
                        <h5>TASK</h5>
                        <p>You have been approached by an existing social media platform to create a new Front End for their application. Design and user experience has not been specified. Working with the official API documentation, plan, design and build a modern front end social media application </p>
                        <h5>REQUIREMENT</h5>
                        <p>All admin functionality is managed by an existing application, This project only covers front-end application for the API</p>
                        <h5>END PRODUCT</h5>
                        <p>SoMe is a microblog, aiming to be a positive contribution in the mix out there.Main target group are Gen Z. Most of the features and design are implemented as planned. Focus in design and coding has been on mobile, as this would be the most likely way the user will interact with the app. There are still some bugs even though is is live, which I plan to fix this summer. Login for testing: E-mail: CathrineTester@stud.noroff.no
                        Password: CathrineTester</p>
                    </Card.Text>
                    <a href="https://github.com/Noroff-FEU-Assignments/project-exam-2-Cathrinesj" target="_blank" rel="noopener noreferrer"><button variant="primary">GitHub</button></a>
                    <a href="https://someprojectexam2.netlify.app/" target="_blank" rel="noopener noreferrer"><button variant="primary">Live site</button></a>
                </Card.Body>
            </Card>  
        </Col>
        </>
    )
}

export default Some;