import Carousel from 'react-bootstrap/Carousel';
import greengurl from '../../illustrations/greengurl_svg.svg';
import projectgurl from '../../illustrations/projectgurl_svg.svg';
import workgurl from '../../illustrations/workgurl_svg.svg'


function Intro() {
  return (
    <Carousel>
      <Carousel.Item interval={3000}>
        <img
          className="d-block w-100"
          src={greengurl} 
          alt="Cathrine Juvsett - Front End Developer - illustration "
        />
        <Carousel.Caption>
          <h3>HI! I am Cathrine</h3>
          <p>Front End developer</p>
        </Carousel.Caption>
      </Carousel.Item>
      <Carousel.Item interval={3000}>
        <img
          className="d-block w-100"
          src={projectgurl} 
          alt="Cathrine Juvsett - Front End Developer - illustration"
        />
        <Carousel.Caption>
          <h3>Portfolio</h3>
          <p>This is my portfolio</p>
        </Carousel.Caption>
      </Carousel.Item>
      <Carousel.Item interval={3000}>
        <img
          className="d-block w-100"
          src={workgurl} 
          alt="Cathrine Juvsett - Front End Developer - illustration"
        />
        <Carousel.Caption>
          <h3>Illustrations</h3>
          <p>Illustration by <a class="link_pro" href="https://freeicons.io/work-illustrations-2/halfbattery-imac-illustration-593">Athul Mc</a>on <a href="https://freeicons.io">freeicons.io</a></p>
        </Carousel.Caption>
      </Carousel.Item>
    </Carousel>
  );
}

export default Intro;