import React, { useState, useRef } from 'react';
import { useForm } from "react-hook-form";
import * as yup from 'yup'; 
import { yupResolver } from "@hookform/resolvers/yup";
import emailjs from '@emailjs/browser';
import Form from 'react-bootstrap/Form';
import FormError from '../../common/FormError/index,jsx';
import { Card } from 'react-bootstrap';
import Image from 'react-bootstrap/Image';
import contactgurl from '../../illustrations/contactgurl_svg.svg';


const schema = yup.object().shape({
  user_name: yup.string().required('Please enter a username'),
  user_email: yup.string().required('Please enter your e-mail'), 
  message: yup.string().min(20).required('Please leave a message'),
});

function ContactForm() {
  const [loginError, setLoginError] = useState(null);

  const {
    reset, 
    formState: { errors }, 
  } = useForm({
    resolver: yupResolver(schema),
  });
  
  const form = useRef();
  
  const sendEmail = (e) => {
    setLoginError(null);
    reset();

    e.preventDefault();
    emailjs.sendForm('service_ocxr1n2', 'template_ecj57fn', form.current, 'LdFpP0Y6vq3wD7S8p')
    .then((result) => {
      console.log(result.text);
    }, (error) => {
      console.log(error.text);
    });
  };
        

  return (
    <>
    <Card className='opacity' id="contact">
      <Card.Body>
        <Image src={contactgurl} fluid roundedCircle />
        <h2>Contact me</h2>
        <p>Form has i tiny bug, no confirmation when you press send! I will recieve it - no worries!</p>
        {loginError && <FormError>{loginError}</FormError>}
        <Form ref={form} onSubmit={sendEmail}>
          <Form.Group className="mb-3" controlId="formBasicEmail">
            <Form.Label>Your name</Form.Label>
            <Form.Control name="user_name" placeholder="Your name" type="text"/>
                  {errors.user_name && <FormError>{errors.user_name.message} </FormError>}
          </Form.Group>

          <Form.Group className="mb-3" controlId="formBasicPassword">
            <Form.Label>Your e-mail</Form.Label>
            <Form.Control name="user_email" placeholder="E-mail" type="email"/>
                  {errors.user_email && <FormError>{errors.user_email.message} </FormError>} 
          </Form.Group>

          <Form.Group className="mb-3" controlId="formBasicPassword">
            <Form.Label>Message</Form.Label>
            <Form.Control name="message" placeholder="Message" type="text"/>
                  {errors.message && <FormError>{errors.message.message} </FormError>} 
          </Form.Group>
    
          <button variant="primary" type="submit" value="send" className='extra-margin'>
            Send
          </button>
        </Form>
      </Card.Body>
    </Card>
    </>
  );


}

export default ContactForm;

