import "./App.css";
import { Routes, Route } from "react-router-dom";
import Home from "./pages/Home";
import About from "./components/About";
import Portfolio from "./components/myPortfolio/Portfolio";
import ContactForm from "./components/Contact";
import { library } from "@fortawesome/fontawesome-svg-core";
import {
  fab,
  faHtml5,
  faCss3Alt,
  faJsSquare,
  faBootstrap,
  faSass,
  faReact,
} from "@fortawesome/free-brands-svg-icons";

library.add(fab, faHtml5, faCss3Alt, faJsSquare, faBootstrap, faSass, faReact);

function App() {
  return (
    <div>
      <Routes>
        <Route path="/*" Component={Home} />
        <Route path="/about" Component={About} />
        <Route path="/portfolio" Component={Portfolio} />
        <Route path="/Contact" Component={ContactForm} />
      </Routes>
    </div>
  );
}

export default App;
